import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Biztos from './Biztos';

/*

*/

const Kiadasok = ({ figyel, setFigyel }) => {

    const [haromKiadas, setHaromKiadas] = useState([]);
    const [isPending, setPending] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        setPending(true);
        fetch('/api/harom-kiadas')
            .then((res) => res.json())
            .then((res) => setHaromKiadas(res.result))
            .finally(() => {
                setPending(false);
            })    
    }, [figyel])

  return (
<>
    {deleteId !== "" && (
        <Biztos onApproved={() => {
            fetch('/api/torles', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(deleteId)
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .finally(() => {
                    setFigyel((...prev) => {
                        return Number(prev) + 1;
                    })
                    setDeleteId("");
                })
        }} onClosed={() => {
            setDeleteId("");
        }}>
            Biztosan törlöd ezt az elemet: '{haromKiadas.find((kiad) => kiad.id == deleteId).name}'? 
        </Biztos>
    )}
    <div className='kiadasLista'>
        <div className="kiadasok">
            {isPending && (<div className='spinner-border'></div>)}
            {!isPending && haromKiadas.map((kiad) => (
            <div className="kiadasSingle" key={kiad.id}>
                <div>
                    <span>{kiad.name}</span>
                    <span style={{
                        color:'crimson',
                        fontWeight:'bold'
                    }}>{new Intl.NumberFormat().format(kiad.price)} ft</span>
                </div>
                <div>
                    <i className='bx bx-trash' onClick={() => {
                        setDeleteId(kiad.id);
                    }}></i>
                    <span className='datum'>{kiad.createdAt}</span>
                </div>
            </div>
            ))}
            
        </div>
    </div>
</>
  )
}

export default Kiadasok