import React, { useState } from 'react'

const KiadasFelvetel = ({ setFigyel }) => {

  const [isPending, setPending] = useState('Kiadás felvétele');

  return (
    <div className='kiadas'>
        <span className="name">Kiadás felvétel</span>
        <form onSubmit={(e) => {
          e.preventDefault();
          setPending('Folyamatban ...');
          const body = {
            name: e.target.elements.name.value,
            price: Number(e.target.elements.price.value)
          }
          fetch('/api/kiadasfelvetel', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
          })
            .then((res) => res.json())
            .then((res) => console.log(res))
            .finally(() => {
              setPending('Kiadás felvétele');
              setFigyel((...prev) => {
                return Number(prev) + 1;
              });
              e.target.elements.name.value="";
              e.target.elements.price.value="";
            })
        }}>
            <input type="text" placeholder='Kiadás neve' name='name'/>
            <input type="number" placeholder='Kiadás összege' name='price'/>
            <button type='submit'>{isPending}</button>
        </form>
    </div>
  )
}

export default KiadasFelvetel