import React from 'react'

const Biztos = ({ onApproved, onClosed, children }) => {
  return (
  <div className='biztos'>
    <i className='bx bx-x' onClick={onClosed}></i>
    <span>{children}</span>
    <div>
      <button type='button' onClick={onApproved}>Igen</button>
      <button type='button' onClick={onClosed}>Mégsem</button>
    </div>
  </div>
  )
}

export default Biztos