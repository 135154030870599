import './App.css'
import { Link, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import KiadasFelvetel from './components/KiadasFelvetel';
import Kiadasok from './components/Kiadasok';
import Adatok from './components/Adatok';
import KiadasLista from './pages/KiadasLista';
import Biztos from './components/Biztos';
import { useEffect, useState } from 'react';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Layout/>}>
        <Route index element={<Home />} />
        <Route path='/kiadasok' element={<KiadasLista/>}/>
      </Route>
    )
  )
  return <RouterProvider router={router} />
}

function Layout() {
  return(
  <div className='keret'>
    <h1 className='title'>Házprojekt, <br/><span>Jani&Timi</span></h1>
    <Outlet />
  </div>
  );
}

function Home() {

  const [adatOrKiadas, setAdatOrKiadas] = useState(false);
  const [figyel, setFigyel] = useState(0);

  return (
    <>
      <KiadasFelvetel setFigyel={setFigyel}/>
      <div className='names'>
        <span className={!adatOrKiadas ? 'active' : ''}
          onClick={() => {
            setAdatOrKiadas(false);
          }}
        >Adatok</span>
        <span className={adatOrKiadas ? 'active' : ''}
          onClick={() => {
            setAdatOrKiadas(true)
          }}
        >Kiadások</span>
      </div>
      {!adatOrKiadas && (
        <Adatok figyel={figyel}/>
      )}
      {adatOrKiadas && <Kiadasok figyel={figyel} setFigyel={setFigyel}/>}
      {adatOrKiadas && (
        <Link to="/kiadasok" className='osszes-link'>Összes kiadás megtekintése</Link>
      )}
    </>
  );
}

export default App;