import React, { useEffect, useState } from 'react'
import Kiadasok from '../components/Kiadasok'
import { Link } from 'react-router-dom'
import Biztos from '../components/Biztos';

const KiadasLista = () => {

  const [kiadasok, setKiadasok] = useState([]);
  const [isPending, setPending] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [figyel, setFigyel] = useState(0);

  useEffect(() => {
    setPending(true);
    fetch('/api/kiadasok')
      .then((res) => res.json())
      .then((res) => setKiadasok(res.result))
      .finally(() => {
        setPending(false);
      })  
  }, [figyel])

  return (
    <>
        {deleteId!==""&&(
          <Biztos onApproved={() => {
            fetch('/api/torles', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(deleteId)
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .finally(() => {
                    setFigyel((...prev) => {
                        return Number(prev) + 1;
                    })
                    setDeleteId("");
                })
        }} onClosed={() => {
            setDeleteId("");
        }}>
            Biztosan törlöd ezt az elemet: '{kiadasok.find((kiad) => kiad.id == deleteId).name}'? 
        </Biztos>
        )}
        <h2 className='lista-h2'>
            <span>Összes kiadás</span>
            <Link to="/" style={{
                color:'black',
                textDecoration:'underline dotted'
            }}>Vissza</Link>
        </h2>
        <div className='kiadasLista'>
          <div className="kiadasok">
            {isPending && (<div className='spinner-border'></div>)}
            {!isPending && kiadasok.map((kiad) => (
            <div className="kiadasSingle" key={kiad.id}>
                <div>
                    <span>{kiad.name}</span>
                    <span style={{
                        color:'crimson',
                        fontWeight:'bold'
                    }}>{new Intl.NumberFormat().format(kiad.price)} ft</span>
                </div>
                <div>
                    <i className='bx bx-trash'
                      onClick={() => {
                        setDeleteId(kiad.id)
                      }}
                    ></i>
                    <span className='datum'>{kiad.createdAt}</span>
                </div>
            </div>
            ))}
            
          </div>
        </div>
    </>
  )
}

export default KiadasLista