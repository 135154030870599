import React, { useEffect, useState } from 'react'

function Adatok({ figyel }) {

  const [adatok, setAdatok] = useState([]);
  const [isPending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    fetch('/api/adatok')
      .then((res) => res.json())
      .then((res) => setAdatok(res.result))
      .finally(() => {
        setPending(false);
      })
    }, [figyel])

  return (
    <div className='adatok'>
        <div>
            <span className='name'>Lakás ára:</span>
            <span className='price'>
              {!isPending ? new Intl.NumberFormat().format(adatok.lakas) : (
                <div className='spinner-border'></div>
              )} ft
            </span>
        </div>
        <div>
            <span className='name'>Ráköltött összeg:</span>
            <span className='price'>
              {!isPending ? new Intl.NumberFormat().format(adatok.rakoltve) : (
                <div className='spinner-border'></div>
              )} ft
            </span>
        </div>
        <div>
            <span className='name'>Összesen:</span>
            <span className='price'>
              {!isPending ? new Intl.NumberFormat().format(adatok.osszesen) : (
                <div className='spinner-border'></div>
              )} ft
            </span>
        </div>
    </div>
  )
}

export default Adatok